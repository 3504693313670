import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import ReadMore from "../components/read-more"
import NewsFlex from "../components/news-flex"
import Footer from "../components/footer"

import hb from "../images/h-b.jpg"
import hbLogo from "../images/h-b-logo.png"
import net4care from "../images/net4care.jpg"

function breakText(t) {
  const arr = t.split("\n");
  const res = [];
  for (const a of arr) {
    res.push(a, <br/>);
  }
  return res;
}

const ProgettiPage = ({ data }) => {
  const blocks = data.allStrapiProject.nodes.sort((a, b) => a.Position - b.Position);

  return <div>
    <Header/>
    
    <div className="content-pad" id="precisione">
      <h2>2023/2024<br/>la precisione</h2>
      <p>
        Il biennio 2023 - 2024 sarà dedicato al tema della PRECISIONE, che sarà affrontata e declinata in tutti
        i suoi aspetti attraverso progetti scientifici e assistenziali, approfondimenti, incontri e confronti
        in presenza e sul web.<br/>
        La Fondazione Silene ideas for CARE nasce da un seme che se ben accudito potrà germogliare ovunque.
      </p>
    </div>

    <div className="content-pad">
      <h2>i progetti in elaborazione</h2>
      <p>
        Silene con la commissione scientifica propone progetti diretti verso lo sviluppo di percorsi di cura e
        formazione su scala internazionale e verso tutti i contesti assistenziali. Il denominatore comune dei
        progetti sarà la realizzazione di network con finalità di sviluppo di idee e tecnologie innovative che
        potranno essere condivise con le realtà socioeconomiche più fragili. Nel caso di progettualità coinvolgenti
        aree del pianeta con standard assistenziale limitato, si inseriranno realtà di cura ed educazione che
        possano progressivamente integrarsi nella pratica quotidiana e costituire una risorsa che la comunità potrà
        gestire autonomamente. Nella realizzazione dei progetti si ricorrerà al supporto di Centri di eccellenza
        che potranno così trasferire le proprie specifiche competenze mediche e infermieristiche.
      </p>
    </div>

    {blocks.map((b, i) =>
      <div className={i%2 ? "columns2 reverse" : "columns2 white-bg"}>
        <div className="image-col">
          <img src={b.Image.localFile.url}/>
        </div>
        <div className="text-col">
          {b.TitleLogo ?
            <img src={b.TitleLogo.localFile.url} className="project-logo"/> :
            <h2 style={{marginBottom: '3px'}}>{b.Title}</h2>
          }
          <b className="subtitle">{b.Subtitle}</b>
          <ReadMore white={i%2 === 0} abstract={breakText(b.Text)}>
            {breakText(b.MoreText)}
          </ReadMore>
        </div>
      </div>
    )}

    <NewsFlex white={true}/>

    <Footer/>
  </div>
}

export default ProgettiPage

export const pageQuery = graphql`
  query {
    allStrapiProject {
      nodes {
        Title
        TitleLogo { localFile { url } }
        Subtitle
        Text
        MoreText
        Image { localFile { url } }
        Position
      }
    }
  }
`
